'use client';

import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { Heading, VStack, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { BsDiscord } from 'react-icons/bs';

import Modal from '../components/modal';

const ContactModal = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  return (
    <>
      <Modal
        header="Contact us"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Text mb="2">
          If you have any questions or need help, please contact us with one of
          the following methods:
        </Text>
        <VStack align="start">
          <VStack align="start">
            <Heading as="h3" size="md">
              Email
            </Heading>
            <Button
              leftIcon={<EmailIcon />}
              variant="link"
              color="hypertext"
              as="a"
              href="mailto:contact@deploymodel.com"
            >
              contact@deploymodel.com
            </Button>
          </VStack>
          <VStack align="start">
            <Heading as="h3" size="md">
              Schedule a Demo / Call
            </Heading>
            <Button
              variant="link"
              leftIcon={<PhoneIcon />}
              as="a"
              color="hypertext"
              href="https://calendly.com/deploymodel/call"
              target="_blank"
              rel="noopener noreferrer"
            >
              calendly.com/deploymodel/call
            </Button>
          </VStack>
          <VStack align="start">
            <Heading as="h3" size="md">
              Chat with us on Discord
            </Heading>
            <Button
              variant="link"
              leftIcon={<BsDiscord />}
              as="a"
              color="hypertext"
              href="https://discord.gg/jqVF6c8X"
              target="_blank"
              rel="noopener noreferrer"
            >
              discord.gg/jqVF6c8X
            </Button>
          </VStack>
        </VStack>
      </Modal>
      <Button variant="link" onClick={() => setIsOpen(true)}>
        Contact Us
      </Button>
    </>
  );
};

export default ContactModal;
