import type { ModalProps as ChakraModalProps } from '@chakra-ui/react';
import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

export interface ModalProps extends ChakraModalProps {
  header?: React.ReactNode;
  isClosable?: boolean;
}

const Modal = ({
  header,
  children,
  isClosable = true,
  ...rest
}: ModalProps) => {
  return (
    <ChakraModal {...rest}>
      <ModalOverlay />
      <ModalContent>
        {header && <ModalHeader>{header}</ModalHeader>}
        {isClosable && <ModalCloseButton />}
        <ModalBody mb="4">{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};

export default Modal;
