'use client';

import { CacheProvider } from '@chakra-ui/next-js';
import type React from 'react';

import { Chakra as ChakraProvider } from '~/lib/components/chakra';

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <CacheProvider>
      <ChakraProvider>{children}</ChakraProvider>
    </CacheProvider>
  );
};

export default Providers;
